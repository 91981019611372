import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
// routing stuff
import { useLocation, Link, useHistory } from 'react-router-dom'
import routes from 'routes/routes'

import { TYPES } from '../../store/reducers/menu.reducer'
import './styles.sass'
import { useHandleUtils } from 'services/useHandleUtils'
import UserStore from 'services/userLocalStorage'

function Navigation() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useLocation()
	const { getMenuList } = useHandleUtils()
	const { auth, menu, geral } = useSelector(store => store)
	const defaultClass = 'pvt-navigation custom'
	const showMenuClass = 'pvt-navivation__show-menu'
	const [classes, setClasses] = useState(defaultClass)
	const hideMenu = () => dispatch({ type: TYPES.HIDE_MENU })
	const scrollToTop = () => {
		if (location.pathname !== '/auth/verification')
			window.scrollTo(0, 0)
	}
	const history = useHistory()

	useEffect(() => {
		hideMenu()
		scrollToTop()
	}, [location])


	useEffect(() => {
		const _classes = menu.visible ? `${defaultClass} ${showMenuClass}` : defaultClass
		setClasses(_classes)
	}, [menu])



	const routesFilter = useMemo(() => {
		//Busca a lista de Menus liberados
		const menuList = getMenuList()
		const routesFilter = routes.filter(route => {
			const routeBlock = []
			if (!menuList?.includes(route.menu)) routeBlock.push(route.path)
			return !(routeBlock.findIndex(r => r === route.path) >= 0)
		})
		const portalId = UserStore.getPortalIdByPathName()
		const pathName = window.location.pathname
		const lastPartPath = pathName.split('/').filter(Boolean).pop()
		const includesInRoute = (routesFilter || []).map(item => item.path).includes(`/${lastPartPath}`)
		//Valida path para ja entrar no com 1 menu selecionado (Se alterar cuidar , entrar logada pelo token do path e reset de senha)
		if (((!includesInRoute && lastPartPath === `${portalId}`)
			|| (lastPartPath === 'unauthorized' || lastPartPath === 'self-service')) // essa parte fica assim devido a tempo depois refatora.
			&& routesFilter?.length > 0) {
			history.push({ pathname: routesFilter[0].path })
		}
		return routesFilter
	}, [routes, geral.portalConfig, auth?.signinToken])

	return (
		<nav className={classes}>
			<ul>
				{routesFilter.map(({ name, path }) => (
					path !== '/unauthorized' &&
					<React.Fragment key={name}>
						<li className={location.pathname === path ? 'active' : null}>
							<Link to={path}>{t(name)}</Link>
						</li>
					</React.Fragment>
				))}
			</ul>
		</nav>
	)

}

export default Navigation