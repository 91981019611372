import * as React from 'react';
import { useEffect } from 'react'
import { useSelector } from 'react-redux';

import { Loading } from 'components/Icons'
import './styles.sass'
import HomeDefault from 'views/HomeDefault/HomeDefault';

function Home() {
    const { geral = {} } = useSelector(state => state);
    const { currentTemplate } = geral;
    const [homeDefault, setHomeDefault] = React.useState(false)

    useEffect(() => {
        checkedHtm();
    }, [])

    let count = 0
    const checkedHtm = () => {
        if (currentTemplate.html || count === 2) {
            setHomeDefault(true);
        } else {
            setTimeout(() => {
                count += 1;
                checkedHtm();
            }, 1000)
        }
    }

    let content;
    if (currentTemplate.html) {
        content = <div dangerouslySetInnerHTML={{ __html: currentTemplate.html }}></div>;
    } else if (homeDefault) {
        content = <HomeDefault />;
    } else {
        content = <div style={{ height: '100vh' }}><Loading /></div>;
    }

    return (
        <div>
            {content}
        </div>
    );
}

export default Home;
