import Label from "components/Label"
import Select from "../CustomSelect/Select"
import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'


export const SelectUserType = ({ onChange, value }) => {
    const { t } = useTranslation()
    const { geral = {} } = useSelector(state => state)
    const { portalUserTypes } = geral.portalConfig
    return (
        <>
            <Select
                placeholder=""
                onChange={onChange}
                isSearchable={false}
                value={value}
                options={
                    (portalUserTypes || [])
                        .map(item => ({ label: t(`signup.label.${item}`), value: item }))
                }
            />
        </>
    )
}