import { axiosInstance } from 'services/api'
import HandleUtils from 'services/handleUtils'
import UserStore from 'services/userLocalStorage'
// Creators
import { Creators as authCreators } from './auth.reducer'

const endPoints = window.endPoints
/**
 * @param {Object} credentials {username, password}
 */
export function signUp(lang, credentials = null) {
  if (!credentials) {
    alert('please provide credentials')
    return
  }

  return (dispatch) => {
    dispatch(authCreators.fetching(true))

    const data = new FormData()

    data.append('name', credentials.name)
    data.append('email', credentials.email)
    data.append('document', credentials.document)
    data.append('phoneNumber', credentials.phoneNumber)
    data.append('country', credentials.country)
    data.append('portalUser', credentials.portalUser)

    axiosInstance
      .post(
        `${endPoints.api.urlPublic}/${lang}/${endPoints.api.signup}`,
        data,
      )
      .then((res) => {
        dispatch(authCreators.signup(res.data))
      })
      .catch((err) => {
        const { response } = err;

        if (response && response.data && response.data.error === "registerAlreadyExist") {
          dispatch(authCreators.setSignupError(response.data.error));
        } else {
          alert(err.message);
        }
      })
  }
}

export function clearSignUpErrors() {
  return (dispatch) => {
    dispatch(authCreators.setSignupError(null));
  };
}

/**
 * @param {Strinng} pincode
 */
export function signUpPinCode(pincode) {
  if (!pincode) {
    console.log('please provide the pincode')
    return
  }

  return (dispatch, getState) => {
    dispatch(authCreators.fetching(true))

    const data = new FormData()

    data.append('pincode', pincode)

    const requestOptions = {
      method: 'POST',
      url: `${endPoints.api.url}/${endPoints.api.signupPincode}`,
      data,
      headers: {
        Authorization: `Bearer ${getState().auth.signupToken}`
      },
    }

    dispatch(authCreators.loginError(null));

    axiosInstance(requestOptions)
      .then(({ data }) => {
        dispatch(authCreators.signup(null))
        dispatch(authCreators.passwordToken(data))
      })
      .catch((err) => dispatch(authCreators.loginError(err.message)))
  }
}

/**
 * @param {Strinng} pincode
 */
export function setPassword(password) {
  if (!password) {
    alert('please provide the pincode')
    return
  }

  return (dispatch, getState) => {
    dispatch(authCreators.fetching(true))

    const data = new FormData()
    data.append('newPassword', password)

    const requestOptions = {
      method: 'POST',
      url: `${endPoints.api.url}/${endPoints.api.savePassword}`,
      data,
      headers: {
        Authorization: `Bearer ${getState().auth.passwordToken}`
      },
    }

    axiosInstance(requestOptions)
      .then(({ data }) => {
        dispatch(authCreators.passwordToken(data))
        dispatch(authCreators.setSignupSuccess(true))
      })
      .catch((err) => alert(err.message))
  }
}

export function changePassword(password, changePasswordToken, callbackSuccess, callbackError) {
  if (!password) {
    alert('please provide the pincode')
    return
  }

  return (dispatch) => {
    dispatch(authCreators.fetching(true))

    const data = new FormData()
    data.append('newPassword', password)

    const requestOptions = {
      method: 'POST',
      url: `${endPoints.api.url}/${endPoints.api.changePassword}`,
      data,
      headers: {
        Authorization: `Bearer ${changePasswordToken}`
      },
    }

    axiosInstance(requestOptions)
      .then(({ data }) => {
        callbackSuccess();
      })
      .catch((err) => {
        callbackError(err.message);
      })
  }
}

/**
 * @param {Object} credentials {username, password}
 */
export function signIn(lang, credentials = null) {
  if (!credentials) {
    alert('please provide credentials')
    return
  }

  return (dispatch) => {
    const data = new FormData()
    data.append('email', credentials.email)
    data.append('password', credentials.password)
    data.append('portalUser', credentials.portalUser)
    dispatch(authCreators.fetching(true))

    axiosInstance
      .post(
        `${endPoints.api.urlPublic}/${lang}/${endPoints.api.singin}`,
        data,
      )
      .then(({ data }) => {
        UserStore.storeEmailHatchedData(data)
        //Authorization(data)
        dispatch(authCreators.signinToken(data))
      })
      .catch((err) => dispatch(authCreators.loginError(err.message)))
  }
}

/**
 * @param {Object} credentials {username, password}
 */
export function signInPinCode(pincode) {
  if (!pincode) {
    console.log('please provide credentials')
    return
  }

  return (dispatch, getState) => {
    const data = new FormData()
    data.append('pincode', pincode)

    const requestOptions = {
      method: 'POST',
      url: `${endPoints.api.url}/${endPoints.api.signInPincode}`,
      data,
      headers: {
        Authorization: `Bearer ${getState().auth.signinToken}`
      },
    }

    dispatch(authCreators.fetching(true))
    dispatch(authCreators.loginError(null))

    axiosInstance(requestOptions)
      .then(({ data }) => {
        UserStore.storeToken(data.token)
        UserStore.storeEmailData(data.email)
        UserStore.storeUserData(data.name)


        UserStore.storeIsAuth(true)
        UserStore.clearEmailHatched()
        dispatch(authCreators.login({ name: data.name, email: data.email, company: data.company }))
        window.location.reload()
      })
      .catch((err) => dispatch(authCreators.loginError(err.message)))

  }
}

/**
 * @desc get data from localstore, this is for scenarios like page refresh
 */
export function getUserDataFromLocalStorage(credentials = null) {
  return (dispatch) => {
    dispatch(authCreators.fetching(true))
    const userData = UserStore.getUserData()
    dispatch(authCreators.login(userData))
  }
}

export function signOut(callback) {
  return (dispatch) => {
    UserStore.clear()
    dispatch(authCreators.logout())
    if (callback) {
      callback()
    }
  }
}

export function getUserAccounts() {
  const requestOptions = {
    method: 'GET',
    url: endPoints.api.account + `?d=${new Date().getTime()}`,
    ...UserStore.getAuthentication(),
  }

  return (dispatch, state) => {
    dispatch(authCreators.fetching(true))
    if (state().auth.user.role === 'master') {
      axiosInstance(requestOptions)
        .then((res) => dispatch(authCreators.getAccounts(res.data)))
        .catch(() => dispatch(authCreators.getAccounts(null)))
    } else {
      dispatch(authCreators.fetching(false))
    }
  }
}

export function resetPassword(lang, login, portalUser, callbackSuccess, callbackError) {
  if (!login) {
    alert('please provide credentials')
    return
  }

  return (dispatch) => {
    const data = new FormData()
    data.append('login', login)
    data.append('portalUser', portalUser)

    axiosInstance
      .post(
        `${endPoints.api.urlPublic}/${lang}/${endPoints.api.resetPassword}`,
        data,
      )
      .then(({ data }) => {
        const userInfo = HandleUtils.getUserInfoByToken(data)
        callbackSuccess(userInfo)
      })
      .catch((err) => {
        console.log("error reset password", err)
        callbackError(err.message);
      })
  }
}

export function switchAccounts(account) {
  return (dispatch, state) => {
    if (state().auth.user.role === 'master') {
      dispatch(authCreators.switchAccount(account))
    }
  }
}

export function alternativeSignIn(token, fullName) {
  if (!token) {
    console.log('please provide token')
    return
  }

  return (dispatch) => {
    UserStore.storeToken(token);
    const userData = {
      name: fullName,
      authApp: true
    };
    UserStore.storeUserData(fullName);
    UserStore.storeAuthApp(true);
    dispatch(authCreators.login(userData));
  }
}
