import axios from 'axios'
import HandleUtils from './handleUtils';
/*import { endPoints } from './api'*/

const endPoints = window.endPoints;
class UserStore {
  static LOCAL_STORAGE_TOKEN = 'pv_token'
  static LOCAL_STORAGE_REFRESH_TOKEN = 'pv_refresh_token'
  static LOCAL_STORAGE_USER_DATA = 'pv_userdata'
  static LOCAL_STORAGE_USER_EMAIL = 'pv_useremail'
  static LOCAL_STORAGE_USER_EMAIL_HATCHED = 'pv_useremail_hatched'
  static LOCAL_STORAGE_AUTH_APP = 'pv_authapp'
  static LOCAL_STORAGE_LANGUAGE = 'pv_mylang'
  static LOCAL_STORAGE_PORTALCONFIG = 'pv_pconfig'
  static LOCAL_STORAGE_ISAUTH = 'pv_isauth'




  static hasToken() {
    return this.getToken() !== null
  }

  static getPortalIdByPathName = () => {
    const splittedPathName = window.location.pathname.replace(`/portal/`, "").split("/")
    return splittedPathName && splittedPathName.length > 0 ? splittedPathName[0] : ""
  }

  static keyByPortal = (keyName) => {
    const portalId = this.getPortalIdByPathName()
    return `${keyName}_${portalId}`;
  }

  static getNewToken() {
    return new Promise((resolve, reject) => {
      axios
        .post(endPoints.api.refreshToken, {
          refresh_token: this.getRefreshToken(),
        })
        .then((response) => {
          this.storeToken(response.data.token)
          this.storeRefreshToken(response.data.refresh_token)

          resolve(response.data.token)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  static storeToken(token) {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_TOKEN);
    localStorage.setItem(key, token)
  }

  static storeRefreshToken(refreshToken) {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_REFRESH_TOKEN);
    localStorage.setItem(key, refreshToken)
  }

  static storeLanguage(lang) {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_LANGUAGE);
    localStorage.setItem(key, lang)
  }

  static storePortalConfig(portalConfig) {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_PORTALCONFIG);
    localStorage.setItem(key, portalConfig)
  }


  static clear() {
    const keyToken = this.keyByPortal(UserStore.LOCAL_STORAGE_TOKEN);
    const keyUser = this.keyByPortal(UserStore.LOCAL_STORAGE_USER_DATA);
    const keyEmail = this.keyByPortal(UserStore.LOCAL_STORAGE_USER_EMAIL)
    const keyEmailHatched = this.keyByPortal(UserStore.LOCAL_STORAGE_USER_EMAIL_HATCHED)
    const keyAuth = this.keyByPortal(UserStore.LOCAL_STORAGE_ISAUTH)
    localStorage.removeItem(keyUser);
    localStorage.removeItem(keyToken);
    localStorage.removeItem(keyEmail);
    localStorage.removeItem(keyEmailHatched);
    localStorage.removeItem(keyAuth);
  }

  static clearEmailHatched() {
    const keyEmailHatched = this.keyByPortal(UserStore.LOCAL_STORAGE_USER_EMAIL_HATCHED)
    localStorage.removeItem(keyEmailHatched)
  }

  static storeUserData(data) {
    const stringfied = JSON.stringify(data);
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_USER_DATA);
    localStorage.setItem(key, stringfied)
  }

  static getUserData() {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_USER_DATA);
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  static storeEmailData(data) {
    const stringfied = JSON.stringify(data);
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_USER_EMAIL);
    localStorage.setItem(key, stringfied)
  }

  static storeEmailHatchedData(data) {
    if (!data) return
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_USER_EMAIL_HATCHED)
    try {
      const userInfo = HandleUtils.getUserInfoByToken(data)
      const emailhached = HandleUtils.generateEmailHatched(userInfo.email)
      localStorage.setItem(key, emailhached)
    } catch (e) {
      console.error(`Error : ${e} in storeEmailHatchedData`)
      localStorage.setItem(key, '***')
    }
  }

  static getEmailData() {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_USER_EMAIL);
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  static getEmailHatchedData() {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_USER_EMAIL_HATCHED);
    const data = localStorage.getItem(key);
    return data;
  }

  static storeIsAuth(data) {
    const stringfied = JSON.stringify(data);
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_ISAUTH);
    localStorage.setItem(key, stringfied)
  }

  static getIsAuth() {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_ISAUTH);
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  static storeAuthApp(data) {
    const stringfied = JSON.stringify(data);
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_AUTH_APP);
    localStorage.setItem(key, stringfied)
  }

  static getAuthApp() {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_AUTH_APP);
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  static getRefreshToken() {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_REFRESH_TOKEN);
    return localStorage.getItem(key);
  }

  static getToken() {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_TOKEN);
    return localStorage.getItem(key);
  }

  static getLanguage() {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_LANGUAGE);
    return localStorage.getItem(key);
  }

  static getPortalConfig() {
    const key = this.keyByPortal(UserStore.LOCAL_STORAGE_PORTALCONFIG);
    return localStorage.getItem(key);
  }

}


export default UserStore
