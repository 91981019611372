import * as React from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next'

import Auth from 'components/Auth'

import Field from 'components/Form/Field'
import Label from 'components/Form/Label'
import Input from 'components/Form/Input'
import Button from 'components/Button'
import Alert from 'components/Alert'
import { Row } from 'components/Layout'

// routing stuff
import { Link, Redirect } from 'react-router-dom'

// reducer
import reducer, { Types, initialState, validationHelper, } from './reducer'
import { useDispatch, useSelector } from 'react-redux'
import { signIn } from 'store/reducers/auth.thunks'
import { Creators as authCreators } from 'store/reducers/auth.reducer'

import SignInSSO from './SignInSSO';
import Select from 'components/Form/CustomSelect/Select';
import { SelectUserType } from 'components/Form/Select/SelectUserType';

function SignIn(props) {
  const captchakey = process.env.REACT_APP_RECAPTCHA_KEY;
  const { t, i18n } = useTranslation()
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const reduxDispatch = useDispatch()
  const { auth, geral = {} } = useSelector(state => state)

  const [emailErrorMsg, setEmailErrorMsg] = React.useState('');
  const [passwordErrorMsg, setPasswordErrorMsg] = React.useState('');
  const [captchaValue, setCaptchaValue] = React.useState('');
  const [systemLang, setSystemLang] = React.useState({ lang: i18n.language === 'pt' ? 'pt-BR' : i18n.language })
  const [portalUser, setPortalUser] = React.useState({ label: '', value: '' })

  React.useEffect(() => {
    return () => {
      if (auth.signUpSuccess) {
        reduxDispatch(authCreators.setSignupSuccess(false))
      }
    }
  }, [auth.signUpSuccess, reduxDispatch])

  function submitForm() {

    const { errors, ...data } = state
    let hasErrors = false

    if (!validationHelper.email.mandatoryValidation(state.email)) {
      dispatch({
        type: Types.ERROR,
        payload: { key: 'email' },
      })
      setEmailErrorMsg(t('signin.validation.error.email'));
      hasErrors = true
    } else if (geral.portalConfig.portalAuthType !== 'AUTH_REGISTRATION_EMAIL_DOCUMENT' && !validationHelper.email.validation(state.email)) {
      dispatch({
        type: Types.ERROR,
        payload: { key: 'email' },
      })
      setEmailErrorMsg(t('signin.validation.error.emailInvalid'));
      hasErrors = true
    }

    if (geral.portalConfig.authType) {
      if (!validationHelper.password.mandatoryValidation(state.password)) {
        dispatch({
          type: Types.ERROR,
          payload: { key: 'password' },
        })
        hasErrors = true
        setPasswordErrorMsg(t('signin.validation.error.password'));
      }
    }

    if (!hasErrors) {
      const newData = { ...data, portalUser: portalUser?.value }
      reduxDispatch(signIn(geral.language, newData))
    }
  }

  function dispatcherHelper(key, value) {
    dispatch({
      type: Types.DATA,
      payload: { [key]: value, key },
    })
  }

  function updateUser({ target }) {
    dispatcherHelper('email', target.value)
    setEmailErrorMsg("");
  }

  function updatePassword({ target }) {
    dispatcherHelper('password', target.value)
    setPasswordErrorMsg("");
  }

  const onChangeCaptcha = _captcha => {
    setCaptchaValue(_captcha);
  }

  if (props?.location?.forceSignOut) {
    document.location.reload(false);
    return null;
  }
  if (auth.isAuthenticated) {
    return <Redirect to="/" />
  }

  if (auth.signinToken) {
    return <Redirect to="/auth/verification" />
  }

  const handlePortalUser = (vl) => {
    setPortalUser(vl)
  }

  return (
    <section>
      <Auth>
        {auth.signUpSuccess && (
          <>
            <Alert
              align="left"
              size="full"
              type="confirm"
              text={<>{t('signin.alert.signUpSuccess')}</>}
            />
            <br />
          </>
        )}
        {auth.error && (
          <div className="alert alert-danger" role="alert">
            {geral.portalConfig.authType ?
              <div  >
                {t('signin.alert.signUpError')}{' '}
                <b>{t('signin.alert.signUpError.again')}</b>
              </div>
              :
              <div  >
                {t('signin.alert.signUpError.mail')}{' '}
                <b>{t('signin.alert.signUpError.again')}</b>
              </div>}
          </div>
        )}
        {props?.location?.successMessage && (
          <>
            <Alert
              align="left"
              size="full"
              type="success"
              text={
                <div>
                  {props?.location?.successMessage}
                </div>
              }
            />
            <br />
          </>
        )}

        <Field>
          <header className="pvt-content-header custom">
            <h2 className="pvt-content-title custom">{geral.portalConfig.authType ? t('signin.title') : t('signin.token.title')}</h2>
          </header>
          <Field>
            <Label label={t('signin.label.access.as')} mandatory={true} />
            <SelectUserType onChange={handlePortalUser} value={portalUser} />
          </Field>
          <Label label={geral.portalConfig.portalAuthType === 'AUTH_REGISTRATION_EMAIL_DOCUMENT' ? t('signin.label.email.or.document') : t('signin.label.email')} relation="user-field" />
          <Input
            id="user-field"
            type="text"
            value={state.email}
            onChange={updateUser}
            invalidMessage={
              state.errors.email.error ? emailErrorMsg : null
            }
          />
        </Field>

        {
          geral.portalConfig.authType ?
            <Field>
              <Label label={t('signin.label.password')} relation="password-field" />
              <Input
                id="password-field"
                type="password"
                value={state.password}
                onChange={updatePassword}
                invalidMessage={
                  state.errors.password.error ? passwordErrorMsg : null
                }
              />
            </Field>
            : ''
        }

        {
          !geral.portalConfig.authType ?
            <Row position={['y-center', 'x-center']}>
              <Field className="singup-recaptcha">
                <ReCAPTCHA sitekey={captchakey} onChange={onChangeCaptcha} hl={systemLang.lang} key={systemLang.lang} />
              </Field>
            </Row>
            : ''
        }

        <Field>
          <Button
            disabled={!geral.portalConfig.authType && captchaValue === '' || !portalUser?.value}
            type="confirm"
            label={
              geral.portalConfig.authType ?
                t('signin.button.label.send')
                : t('signin.button.label.get')}
            classie="full"
            onClick={submitForm}
          />
          {
            (geral.portalConfig?.ssoProviders || []).map((ssoProvider, index) =>
              <SignInSSO
                key={`SignInSSO-${++index}`}
                isDisabled={!geral.portalConfig.authType && captchaValue === ''}
                ssoProvider={ssoProvider}
              />
            )
          }
        </Field>
        {
          geral.portalConfig.authType ?
            <Link to="/recovery-password" className="pvt-btn2 txt-al-center custom">
              {t('signin.forgotPassword')}
            </Link> : ''
        }

      </Auth>
    </section >
  )
}

export default SignIn
