import React from 'react';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
    const { t } = useTranslation()
    return (
        <section>
            <h1>{t('routes.label.unauthorization')}</h1>
        </section>
    );
}

export default Unauthorized;
