import * as React from 'react'
import { useTranslation } from 'react-i18next'


import Auth from 'components/Auth'

import Alert from 'components/Alert'
import Field from 'components/Form/Field'
import Label from 'components/Form/Label'
import Input from 'components/Form/Input'
import Button from 'components/Button'
import { Loading } from 'components/Icons'


// reducer
import reducer, { Types, initialState, validationHelper, } from './reducer'
import { useDispatch } from 'react-redux'

import { changePassword } from 'store/reducers/auth.thunks'

//ReactPasswordStrength
import ReactPasswordStrength from 'react-password-strength';

// router
import { useLocation, useHistory } from 'react-router-dom'

import './styles.sass'


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ChangePassword() {
  const { t } = useTranslation()
  const [pwErrorMessage, setPwErrorMessage] = React.useState("");
  const [serviceMessage, setServiceMessage] = React.useState(null);
  const [sending, setSending] = React.useState(false);

  const [state, dispatch] = React.useReducer(reducer, initialState)
  const reduxDispatch = useDispatch()
  let query = useQuery();
  const history = useHistory();

  const [changePasswordToken, setChangePasswordToken] = React.useState(null)

  const MINLENGTH = 10
  const MINSCORE = 4

  React.useEffect(() => {
    const _token = query.get("resetToken")
    if (_token) {
      setChangePasswordToken(_token);
    }
  }, [query])

  function submitForm() {
    const force = document.querySelectorAll(`.ReactPasswordStrength`);

    let hasErrors = false

    if (!validationHelper.password.mandatoryValidation(state.password)) {
      force[0].classList.add('error')
      setPwErrorMessage(t('setpassword.validation.error.password'));
      hasErrors = true
    } else if (!validationHelper.password.validation(state.password)) {
      force[0].classList.add('error')
      setPwErrorMessage(t('setpassword.validation.error.passwordInvalid'));
      hasErrors = true
    } else {
      setPwErrorMessage("");
    }

    if (!validationHelper.confirmPassword.validation(state.password, state.confirmPassword)) {
      force[1].classList.add('error')
      dispatch({
        type: Types.ERROR,
        payload: { key: 'confirmPassword' },
      })
      hasErrors = true
    }

    if (!hasErrors) {
      setSending(true)
      setServiceMessage(null);
      reduxDispatch(changePassword(state.password, changePasswordToken, callbackSuccess, callbackError))
    }

  }

  function callbackSuccess() {
    setServiceMessage(null);
    setSending(false);
    history.push({
      pathname: '/signin',
      successMessage: t('changePassword.service.success'),
    })

  }

  function callbackError(error) {
    setSending(false);
    setServiceMessage({
      type: "error",
      message: t('changePassword.service.error') + error
    })
  }

  function dispatcherHelper(key, value) {
    dispatch({
      type: Types.DATA,
      payload: { [key]: value, key },
    })
  }

  const changeFieldPass = (score, pass, isValid) => {
    dispatcherHelper('password', score.password)
    let cod = validationHelper.password.validation(score.password)
    handleForce(cod, score.score, 0);
    setPwErrorMessage("");
    setServiceMessage(null);
  }

  const changeFieldPassConfirm = (score, pass, isValid) => {
    dispatcherHelper('confirmPassword', score.password)
    let cod = validationHelper.password.validation(state.password, score.password)
    handleForce(cod, score.score, 1);
    setServiceMessage(null);
  }

  const handleForce = (cod, score, i) => {
    const force = document.querySelectorAll(`.ReactPasswordStrength`);
    const strength = document.querySelectorAll(`.ReactPasswordStrength-strength-desc`);

    if (score !== 0) {
      if (!cod) {
        force[i].classList.remove(`is-strength-${score}`)
        force[i].classList.add('is-strength-1')
        strength[i].innerText = "Senha Fraca"
      } else {
        force[i].classList.remove('is-strength-1');
        force[i].classList.add(`is-strength-${score}`)
        strength[i].innerText = "Muito Forte"
      }
    }
  }

  return (
    <section>
      <header className="pvt-content-header custom">
        <h1 className="pvt-content-title custom">{t('changepassword.title')}</h1>
      </header>
      <Auth>

        {serviceMessage && (
          <Field>
            <Alert
              align="left"
              size="full"
              type={serviceMessage.type}
              text={
                <>
                  { serviceMessage.message}
                </>
              }
            />
          </Field>
        )}

        {sending ?
          <p><Loading /></p>
          :
          <>
            <Field>
              <ul className="creteria-pass custom pvt-alert-disclaimer">
                <li><em>{t('setpassword.password.rule1')}</em></li>
                <li><em>{t('setpassword.password.rule2')}</em></li>
                <li><em>{t('setpassword.password.rule3')}</em></li>
                <li><em>{t('setpassword.password.rule4')}</em></li>
                <li><em>{t('setpassword.password.rule5')}</em></li>
              </ul>
              <Label label={t('setpassword.label.password')} relation="password-field" />
              <ReactPasswordStrength
                as={Input}
                type="password"
                style={{ display: 'form-group' }}
                minLength={MINLENGTH}
                minScore={MINSCORE}
                scoreWords={[t('setpassword.PasswordStrength.scoreWords1'),
                t('setpassword.PasswordStrength.scoreWords2'),
                t('setpassword.PasswordStrength.scoreWords3'),
                t('setpassword.PasswordStrength.scoreWords4'),
                t('setpassword.PasswordStrength.scoreWords5')]}
                tooShortWord={t('setpassword.PasswordStrength.tooShortWord')}
                changeCallback={(score, pass, isValid) => changeFieldPass(score, pass, isValid)}
                inputProps={{
                  name: "password",
                  value: "password",
                  autoComplete: "off",
                  className: "form-control custom-ReactPasswordStrength-input custom",
                  placeholder: t('setpassword.placeholder.password')
                }} />
              <span className="error-message custom">{pwErrorMessage ? pwErrorMessage : null}</span>
            </Field>

            <Field>
              <Label label={'Confirme a senha'} relation="passwordConfirmation-field" />
              <ReactPasswordStrength
                style={{ display: 'form-group' }}
                minLength={MINLENGTH}
                minScore={MINSCORE}
                scoreWords={[t('setpassword.PasswordStrength.scoreWords1'),
                t('setpassword.PasswordStrength.scoreWords2'),
                t('setpassword.PasswordStrength.scoreWords3'),
                t('setpassword.PasswordStrength.scoreWords4'),
                t('setpassword.PasswordStrength.scoreWords5')]}
                tooShortWord={t('setpassword.PasswordStrength.tooShortWord')}
                changeCallback={(score, pass, isValid) => changeFieldPassConfirm(score, pass, isValid)}
                inputProps={{
                  name: "confirmPassword",
                  value: "confirmPassword",
                  autoComplete: "off",
                  className: "form-control custom-ReactPasswordStrength-input custom",
                  placeholder: t('setpassword.placeholder.confirmPassword')
                }} />
              <span className="error-message custom">{state.errors.confirmPassword.error ? t('setpassword.validation.error.confirmPassword') : null}</span>
            </Field>

            <Button
              type="confirm"
              label={t('changepassword.button.label.send')}
              classie="full"
              onClick={submitForm}
            />
          </>
        }
      </Auth >
    </section >
  )
}

export default React.memo(ChangePassword)
