import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmail } from 'validator'
import ReCAPTCHA from 'react-google-recaptcha';

import Auth from 'components/Auth'

import Alert from 'components/Alert'
import Field from 'components/Form/Field'
import Label from 'components/Form/Label'
import Input from 'components/Form/Input'
import Button from 'components/Button'
import { Row } from 'components/Layout'
import { BackArrow, Loading } from 'components/Icons'

// routing stuff
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { resetPassword } from 'store/reducers/auth.thunks'
import HandleUtils from '../../services/handleUtils'
import { SelectUserType } from 'components/Form/Select/SelectUserType';

function LoginPinCode() {
  const captchakey = process.env.REACT_APP_RECAPTCHA_KEY;
  const { t } = useTranslation()
  const { geral = {} } = useSelector((store) => store)
  const reduxDispatch = useDispatch()

  const [error, setError] = React.useState(false)
  const [login, setLogin] = React.useState("")
  const [captchaValue, setCaptchaValue] = React.useState('');
  const [serviceMessage, setServiceMessage] = React.useState(null);
  const [sending, setSending] = React.useState(false);
  const useDocument = geral.portalConfig.portalAuthType === 'AUTH_REGISTRATION_EMAIL_DOCUMENT'
  const [portalUser, setPortalUser] = React.useState({ label: '', value: '' })

  function submitForm() {
    if (!login || (!isEmail(login) && !useDocument)) {
      setError(true)
      return
    }
    setError(false)
    setSending(true);
    setServiceMessage(null);
    reduxDispatch(resetPassword(geral.language, login, portalUser.value, callbackSuccess, callbackError))
  }

  function onChange({ target }) {
    setLogin(target.value)
    setError(false);
    setServiceMessage(null);
  }

  function callbackSuccess(userInfo) {
    const emailHatched = HandleUtils.generateEmailHatched(userInfo?.email)
    setLogin('');
    setError(false);
    setSending(false);
    setServiceMessage({
      type: "success",
      message: t('recoveryPassword.service.success', { email: emailHatched })
    });

  }

  function callbackError(error) {
    setSending(false);
    setServiceMessage({
      type: "error",
      message: t('recoveryPassword.service.error') + error
    })
  }

  const handlePortalUser = (vl) => {
    setPortalUser(vl)
  }

  return (
    <section>
      <header className="pvt-content-header custom">
        <h1 className="pvt-content-title custom">{t('recoveryPassword.title')}</h1>
      </header>
      <Auth>
        <Field>
          <Row position={['y-center', 'x-start']}>
            <Link className="pvt-backbutton custom" to="/signin">
              <BackArrow />
              <span>{t('button.label.back')}</span>
            </Link>
          </Row>
        </Field>

        <Field>
          <Alert
            align="center"
            size="full"
            text={
              <>
                <b>
                  <b>{useDocument ? t('recoveryPassword.alert.typeYourEmailOrDocument')
                    : t('recoveryPassword.alert.typeYourEmail')}</b>
                </b>
              </>
            }
          />
        </Field>

        {serviceMessage && (
          <Field>
            <Alert
              align="left"
              size="full"
              type={serviceMessage.type}
              text={
                <>
                  {serviceMessage.message}
                </>
              }
            />
          </Field>
        )}

        {sending ?
          <p><Loading /></p>
          :
          <>
            <Field>
              <Label label={t('signup.label.i.am')} mandatory={true} />
              <SelectUserType onChange={handlePortalUser} value={portalUser} />
            </Field>
            <Field>
              <Label label={useDocument ? t('recoveryPassword.label.typeYourEmailOrDocument')
                : t('recoveryPassword.label.typeYourEmail')} relation="password-field" />
              <Input value={login} name="login" id="login" type="text" required
                onChange={onChange}
                invalidMessage={error ? t('recoveryPassword.validation.error.email') : null} />
            </Field>

            <Field>
              <ReCAPTCHA sitekey={captchakey} onChange={(value) => setCaptchaValue(value)} />
            </Field>

            <Field>
              <Button
                onClick={submitForm}
                type="confirm"
                label={t('button.label.send')}
                classie="full"
                disabled={!captchaValue || !portalUser?.value} />
            </Field>
          </>
        }
      </Auth>
    </section>
  )
}

export default LoginPinCode
