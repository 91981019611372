import * as React from 'react'
import { useTranslation } from 'react-i18next'

import PreferencesAccordion from './PreferencesAccordion'

import NotLogged from 'components/NotLogged'
import { Loading } from 'components/Icons'

import {
  getPreferences
} from 'store/reducers/preference.thunks'

import { useDispatch, useSelector } from 'react-redux'

import './styles.sass'

function Preferences() {
  const { t } = useTranslation()
  const { auth, preferences, geral = {} } = useSelector((store) => store)
  const reduxDispatch = useDispatch()

  React.useEffect(() => {
    if (auth.isAuthenticated && geral.language) {
      reduxDispatch(getPreferences(geral.language))
    }
    // eslint-disable-next-line
  }, [reduxDispatch, geral.language])


  if (!auth.isAuthenticated) {
    return (
      <section>
        <NotLogged />
      </section>
    )
  }
  return (
    <section>
      <header className="pvt-content-header custom">
        <h1 className="pvt-content-title custom">{t('preferences.title')}</h1>
        <p className="pvt-content-desc custom">
          {t('preferences.content.description')}
        </p>
      </header>

      {preferences.fetching ?
        <p><Loading /> {t('yourPersonalData.loading')}</p>
        : null
      }

      {!preferences.fetching && (!preferences?.preferences || !preferences?.preferences.length) ?
        <p>{t('yourRequests.noregister')}</p>
        : null
      }

      {preferences?.preferences.map((preference, index) => (
        <article key={preference.name} className="pvt-preference custom pvt-card">
          <h4>{preference.name}</h4>
          {preference.consents.map((consent, indexChild) => (
            <PreferencesAccordion key={`${index}-${indexChild}-${consent.title}`} consent={consent} alwaysActive={consent.type === 'R'}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                {!!(consent.reason) ? <p>{consent.reason}</p> : null}
                {!!(consent.retentionDate) ? <p><span style={{ fontWeight: 700 }}>{t("date.label.retention")}</span>&nbsp;{consent.retentionDate}</p> : null}
                {(consent.userState === false && !!(consent.revokeDate)) ? <p><span style={{ fontWeight: 700 }}>{t("date.label.revoke")}</span>&nbsp;{consent.revokeDate}</p> : null}
              </div>
            </PreferencesAccordion>
          ))}
        </article>
      ))}
    </section>
  )
}

export default Preferences