import { useSelector } from "react-redux"
import HandleUtils from "./handleUtils"
import UserStore from "./userLocalStorage"

export const useHandleUtils = () => {
    const { auth, geral } = useSelector(store => store)

    //Responsavel de verificar dominio do email para liberação de SELF_SERVICE
    const handleAthorizedSelfDomain = () => {
        const selfDomains = (geral?.portalConfig?.selfDomains || []).map(item => item.domain)
        const userEmail = UserStore.getEmailData()
        let domainBool = false
        if (selfDomains?.length > 0) {
            if (userEmail?.includes("@")) {
                const domain = userEmail.split("@")
                const domainEmail = "@" + domain[1]
                domainBool = selfDomains.includes(domainEmail)
            } else if (!auth.isAuthenticated) {
                domainBool = true
            }
        }
        return domainBool
    }

    //Responsavel por entregar lista de menus liberados
    const getMenuList = () => {
        const { portalOpenBankingOk, portalMenu } = geral.portalConfig
        const portalUserType = HandleUtils.getUserInfoByTokenInStorage()?.portalUserType
        let portalMenuConcat = [...(portalMenu || [])]
        if (portalUserType === "RESPONDENT") { // Se for respondet e estiver o dominio cadastrado add menu SELF_SERVICE
            portalMenuConcat = handleAthorizedSelfDomain() ? ["SELF_SERVICE"] : ['UNAUTHORIZED_SELF_SERVICE']
        } else if (portalOpenBankingOk) { // se tem openbanking habilitado add o menu OPEN_BANKING
            portalMenuConcat = [...portalMenuConcat, "OPEN_BANKING"]
        }
        return portalMenuConcat
    }

    return { getMenuList }
}