import React from 'react'

// import Cookies from 'views/Cookies'
import Preferences from 'views/Preferences'
import YourRequests from 'views/YourRequests'
import YourPersonalData from 'views/YourPersonalData'
import SignIn from 'views/SignIn'
import SignInSsoCallback from 'views/SignIn/SignInSsoCallback'
import SignUp from 'views/SignUp'
import LoginPinCode from 'views/LoginPinCode'
import RecoveryPassword from 'views/RecoveryPassword'
import SingUpPinCode from 'views/SingUpPinCode'
import { SetPassword, ChangePassword } from 'views/SetPassword'
import SignupAccountAlreadyExist from 'views/SignupAccountAlreadyExist'
import Home from 'views/Home'
import YourRequestDetail from 'views/YourRequests/YourRequestDetail'
import GetRequetsByProtocol from 'views/YourRequests/GetRequetsByProtocol'
import DataShare from 'views/DataShare'
import SelfService from 'views/SelfService'
import './styles.css'
import IncidentsSelfService from 'views/IncidentsSelfService/IncidentsSelfService'
import RequestSelfService from 'views/RequestSelfService/RequestSelfService'
import Unauthorized from 'views/Unauthorized'

export const getRequestByProtocol = window.endPoints.api.getRequestByProtocol


export const navRoutes = [
  {
    path: '/',
    name: 'routes.label.home',
    exact: true,
    menu: 'HOME',
    component: () => <Home />,
  },
  {
    path: '/preferences',
    name: 'routes.label.consents',
    exact: true,
    menu: 'CONSENT',
    component: () => <Preferences />,
  },
  {
    path: '/your-requests',
    name: 'routes.label.requests',
    exact: true,
    menu: 'DSAR',
    component: () => <YourRequests />,
  },
  {
    path: '/data-share',
    name: 'routes.label.openBanking',
    exact: false,
    menu: 'OPEN_BANKING',
    component: () => <DataShare />,
  },
  {
    path: '/your-personal-data',
    name: 'routes.label.yourPersonalData',
    exact: true,
    menu: 'PERSON_DATA',
    component: () => <YourPersonalData />,
  },
  {
    path: '/self-service',
    name: 'routes.label.selfService',
    exact: false,
    menu: 'SELF_SERVICE',
    component: () => <SelfService />
  },
  {
    path: `/unauthorized`,
    name: 'routes.label.unauthorization_selfService',
    menu: 'UNAUTHORIZED_SELF_SERVICE',
    component: () => <Unauthorized />,
  }
]

const nonNavRoutes = [
  {
    path: '/signin',
    name: 'routes.label.signin',
    exact: true,
    component: (props) => <SignIn {...props} />,
  },
  {
    path: '/signin/sso/callback/:tokenSso',
    name: 'routes.label.signin',
    exact: true,
    component: (props) => <SignInSsoCallback {...props} />,
  },
  {
    path: '/signup',
    name: 'routes.label.signup',
    exact: true,
    component: () => <SignUp />,
  },
  {
    path: '/auth/verification',
    name: 'routes.label.authVerification',
    exact: true,
    component: () => <LoginPinCode />,
  },

  {
    path: '/auth/signup/pincode',
    name: 'routes.label.authSignupPincode',
    exact: true,
    component: () => <SingUpPinCode />,
  },
  {
    path: '/recovery-password',
    name: 'routes.label.recoveryPassword',
    exact: true,
    component: () => <RecoveryPassword />,
  },
  {
    path: '/auth/set-password',
    name: 'routes.label.authSetPassword',
    exact: true,
    component: () => <SetPassword />,
  },
  {
    path: '/auth/change-password',
    name: 'routes.label.authSetPassword',
    exact: true,
    component: () => <ChangePassword />,
  },
  {
    path: '/signup/account-already-exist',
    name: 'routes.label.accountAlreadyExist',
    exact: true,
    component: () => <SignupAccountAlreadyExist />,
  },
  {
    path: '/your-requests/:protocol',
    name: 'routes.label.requestDetail',
    exact: true,
    component: () => <YourRequestDetail />,
  },
  {
    path: `/${getRequestByProtocol}`,
    name: 'findRequestByProtocol.title',
    component: () => <GetRequetsByProtocol />,
  },
  {
    path: `/${getRequestByProtocol}/:protocol`,
    name: 'routes.label.requestDetail',
    component: () => <YourRequestDetail />,
  },
  {
    path: `/self-service-incident`,
    name: 'label.self.service.incident.create',
    component: () => <IncidentsSelfService />,
  },
  {
    path: `/self-service-requests`,
    name: 'label.self.service.request.label',
    component: () => <RequestSelfService />,
  }

]
export { nonNavRoutes }
export default [...navRoutes]
